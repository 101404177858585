.mainContainer {
    padding: 10px;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerContainer .title {
    font-size: 0.8rem;
    font-weight: 600;
}
.bottomLine {
    opacity: 0.4;
    color: grey;
    margin-bottom: 5px;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.firstCellFlex {
    display: flex;
    align-items: center;
}

.firstCellFlex .driverImgCont {
    width: 40px;
    height: 40px;
    /*border: 3px solid;*/
    /*border-radius: 50%;*/
}
.firstCellFlex img {
    width: 40px;
    height: 40px;
    /*border-radius: 50%;*/
}
.firstCellInfo {
    margin-left: 10px;
}

.headerFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.productName {
    text-transform: capitalize;
}
.productCat {
    text-transform: capitalize;
    font-size: 12px;
}

.belowLabel {
    font-weight: bold;
    margin-left: 10px;
}

.infoTitle{
    margin-bottom: 15px;
    padding-bottom: 5px;
    margin-left: 15px;
    border-bottom: 1px solid;
    width: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.heading{
    margin-right: 5px;
}

.featured{
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 20px;
    text-transform: capitalize;
    background-color: rgba(250, 139, 12, 0.082);
    color: rgb(250, 139, 12);
}

.industry{
    font-size: 0.7rem;
    font-weight: 600;
}

.userForm{
    margin: 0 30%;
}

.paperBackground{
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    background-color: white;
}

.lhs{
    flex: 0.5;
}

.newLine{
    height: 4px;
    width: 30px;
    border-radius: 10px;
    margin-left: 1px;
    background-image: linear-gradient(265deg , #7467F0,#2896E9) !important;
}

.openIcon{
    color: #2896E9 !important;
}

.subText{
    font-size: 0.7rem;
    margin-left: 5px;
    color: #2896E9;
}

/*Create*/
.btnCont {
    display: flex;
    justify-content: flex-end;
}
.formContainer{
    flex: 1;
}

.imgField{
    margin-left: 10px;
}
.nameField{
    margin-left: 10px;
}

.outerFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
}

.resetBtn{
    background: #F7A728 !important;
    padding: 20px 50px !important;
    color: white !important;
    border-radius: 5px !important;
}

.enter{
    font-size: 0.8rem;
    margin-left: 10px;
    font-weight: 600;
    /*padding-bottom: 10px;*/
    display: block;
}

.createBtn{
    background-color: #29CB97 !important;
    color: #ffffff !important;
    padding: 12px 35px !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
}

.newLines{
    height: 4px;
    width: 30px;
    border-radius: 10px;
    margin-left: 20px;
    background-image: linear-gradient(265deg , #7467F0,#2896E9) !important;
}

@media only screen and (max-width:769px) {
    #mobileResponsiveness{
        display: flex;
        flex-direction:column !important;
    }
}
