.container {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex: 0.5;
  /* max-width: 600px; */
}
.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.headerContainer .title {
  font-size: 0.8rem;
  font-weight: 600;
}
.textCenter {
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
}
.tableWrapper {
  margin-top: 15px;
}
.bgColor {
  border-radius: 10px;
  min-width: 100px;
  height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.desktopTable {
  display: block !important;
}
.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 1px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.filters {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.select {
  flex: 1;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  font-size: 0.7rem;
}
.btnClass {
  font-size: 0.8rem !important;
  font-weight: 500;
}
.des {
  font-size: 0.75rem;
  font-weight: 500;
  color: black;
}
.des > span {
  color: #818181 !important;
  margin-left: 10px;
}
.activeBtn {
  background: #2896e9 0% 0% no-repeat padding-box !important;
  color: white !important;
}
.leftCon {
  flex: 1 1;
  display: flex;
  gap: 20px;
}
.rightCon {
  /* font-size: 12px; */
  margin-bottom: 10px;
  /* color: gray; */
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  justify-content: space-between;
}
.legendContainer {
  display: flex;
  margin: 20px 0;
}
