.container { 
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filters{
    display: flex;
    width: 50%;
    align-items: center;
    gap: 10px;
}
.radioWrapper{
  display: flex;
  justify-content: flex-end;
}