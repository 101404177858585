.mainContainer {
  padding: 10px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerContainer .title {
  font-size: 0.8rem;
  font-weight: 600;
}
.bottomLine {
  opacity: 0.4;
  color: grey;
  margin-bottom: 5px;
}
.btnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}
.imgWrap {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstCellFlex {
  display: flex;
  align-items: center;
}

.firstCellFlex .driverImgCont {
  width: 40px;
  height: 40px;
  /*border: 3px solid;*/
  /*border-radius: 50%;*/
}
.firstCellFlex img {
  width: 40px;
  height: 40px;
  /*border-radius: 50%;*/
}
.firstCellInfo {
  margin-left: 10px;
}

.headerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productName {
  text-transform: uppercase;
}
.productCat {
  text-transform: capitalize;
  font-size: 12px;
}
.HODUpperCase {
  text-transform: uppercase;
}
.belowLabel {
  font-weight: bold;
  margin-left: 10px;
}

.infoTitle {
  margin-bottom: 15px;
  padding-bottom: 5px;
  margin-left: 15px;
  border-bottom: 1px solid;
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.heading {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}

.featured {
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 20px;
  text-transform: capitalize;
  background-color: rgba(250, 139, 12, 0.082);
  color: rgb(250, 139, 12);
}

.industry {
  font-size: 0.7rem;
  font-weight: 600;
}

.userForm {
  margin: 0 30%;
}

.paperBackground {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
}

.lhs {
  flex: 0.5;
}
.editBtnWrap {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 1px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.captialize {
  text-transform: capitalize;
}

/* create */
.btnCont {
  display: flex;
  justify-content: flex-end;
}
.formContainer {
  flex: 1;
}

.imgField {
  margin-left: 10px;
}
.nameField {
  margin-left: 10px;
}

.outerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.resetBtn {
  background: #f7a728 !important;
  padding: 20px 50px !important;
  color: white !important;
  border-radius: 5px !important;
}

.enter {
  font-size: 0.8rem;
  margin-left: 10px;
  font-weight: 600;
  /*padding-bottom: 10px;*/
  display: block;
}

.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  white-space: nowrap !important;
}
.edit {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 10px 20px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
  font-size: 0.875rem;
}
.btnWrap {
  display: flex;
  align-items: center;
  gap: 30px;
}
.newLines {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 20px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.imageContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.nameWrapper {
  flex: 1;
}
.checkBox {
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  align-items: center;
  color: #2896e9;
  font-weight: 500;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.redField {
  color: #e92828;
  font-weight: bold;
}
.btnWrapperGap {
  display: flex;
  gap: 15px;
}
.InterviewPopUpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 366px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 80px #00000011;
  border-radius: 10px;
  padding: 35px 25px;
}
.closeWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.descriptionStrong {
  margin-top: 20px;
  margin-bottom: 30px;
}
.headingText {
  font-size: 1.8rem;
  font-weight: 600;
}
.confirmedWrapper {
  margin-top: 20px;
}
.confirmWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainContainer {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  height: 90%;
}

/* USC Create css */
