.legend {
  display: flex;
  gap: 10px;
}

.legend span {
  display: flex;
  align-items: center;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.green {
  color: green;
}

.purple {
  color: purple;
}
