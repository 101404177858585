.positionR {
  position: relative;
}
.fileUpload {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
}

.fileName {
  padding-left: 10px;
  border: 1px solid #a9adaf;
  display: flex;
  align-items: center;
  /* padding-left: 10px; */
  font-size: 16px;
  /*padding: 5px;*/
  flex: 1;
}

.fileLabel {
  display: block;
  padding: 10px 5px;
  color: #fff;
  /* background-image: linear-gradient(265deg, #ab183d, #e56056); */
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
  /*background: #4B80EA;*/
  transition: background 0.3s;
  /*max-width: 90px;*/
}
.imgFileLabel {
  display: block;
  padding: 5px 5px;
  color: #fff;
  background: #11b1d2;
  transition: background 0.3s;
  text-align: center;
}
.imgLowerContainer {
  text-align: center;
}

.fileInput {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  cursor: pointer;
}
.fileInput1 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 214%;
  cursor: pointer;
}
.image {
  width: 100px;
  height: 100px;
  display: inline-block;
  border: 1px solid #c2c2c2;
  border-radius: 50%;
  background-color: lightgrey;
}

.imageBtnContainer {
  width: 100px;
  position: relative;
}
.imageBtnContainerShow {
  width: 250px;
  position: relative;
}

.imageBtnContainerNew {
  width: 100%;
  position: relative;
}

.anchorTag {
  right: 0px;
  bottom: -12px;
  margin: 0px;
  position: absolute;
  margin-left: 5px;
  font-size: 0.6rem;
  /* text-decoration: none; */
  color: #536dfe;
}

.wrapper {
  border: 1px solid black;
  border-style: dotted;
}

.imgEditBtn {
  position: absolute;
  border: 2px solid;
  border-radius: 30px;
  background: white;
  padding: 3px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0px;
  bottom: 0px;
}

.cameraIcon {
  background-color: rgb(95, 99, 242);
  border-radius: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
}

.file_upload__label {
  /*display: block;*/
  /*color: #2196F3 ;*/
  transition: background 0.3s;
  position: relative;
}
.file_upload__label:hover {
  cursor: pointer;
  background: #000;
}

.file_upload__label:hover {
  cursor: pointer;
  background: #000;
}

.file_upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.file_upload__input:hover {
  cursor: pointer;
}

.profileImage {
  text-align: center;
}
.profileImage img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: white;
}

.file_upload {
  position: relative;
  display: inline-block;
  /*width: 100%;*/
  text-align: center;
}

.ratingInput {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.imagePlus {
  width: 100px;
  height: 100px;
  display: inline-block;
  border: 1px solid #c2c2c275;
  background-color: #f6f7ff;
}
.imagePlusShow {
  height: 300px;
  width: calc((300px * 16) / 9);
  display: inline-block;
  border: 1px solid #c2c2c275;
  background-color: #f6f7ff;
  border-radius: 5px;
  cursor: pointer;
}

.imagePlusNew {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c2c2c275;
  background-color: #f6f7ff;
}

.imgFileLabelPlusShow {
  position: absolute;
  top: 99px;
  left: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.imgFileLabelPlus {
  position: absolute;
  top: 30px;
  left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plus {
  color: #9699b0;
  font-size: 24px;
}

.textUpload {
  font-weight: 600;
  font-size: 0.8rem;
  padding: 3px;
}

.tooltipFlex {
  display: flex;
  align-items: center;
  width: 100px;
}

.tipText {
  font-size: 0.8rem;
  margin-left: 7px;
  font-weight: 600;
  display: block;
  text-align: center;
}
