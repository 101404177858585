.outerFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
}

.newLine{
    height: 4px;
    width: 30px;
    border-radius: 10px;
    margin-left: 20px;
    background-image: linear-gradient(265deg , #7467F0,#2896E9) !important;
    margin-top: -10px;
}


.plainPaper{
    border-radius: 10px;
    padding: 1.2rem;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 20px 80px #00000011;
    margin-top: 25px;
}

.heading{
    font-size: 0.8rem;
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.mainFlex{
    display: flex;
    /*align-items: center;*/
}

.vertical {
    border-left: 1px dashed #B8C5D3;
    max-height: 100px;
    flex: 0.3;
}

.key{
    color: #818181;
    font-weight: 500;
    margin-bottom: 7px;
}

.value{
    font-weight: 600;
    width: 150px;
    color: black;
    display: inline-block;
}

.newContainer{
    font-size: 0.7rem;
}

.left{
    flex: 1.4;
    
}
.left221{
    flex: 1.4;
    display: flex;
    gap: 15px;
}
.claimimg{
    width: 50px;
    aspect-ratio: 1/1;
}
.right{
    /*margin-left: 60px;*/
    flex: 1;
}

.horizontal{
    border-bottom: 1px dashed #B8C5D3;
    margin-top: 15px;
    margin-bottom: 22px;
}

.posted{
    font-size: 0.7rem;
    margin-left: 10px;
}

.editBtn{
    display: flex;
    justify-content: flex-end;
}

.edit{
    color: #29CB97 !important;
    border: 2px solid #29CB97 !important;
    padding: 10px 40px !important;
    border-radius: 30px !important;
    font-weight: 600 !important;
}

.editFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statusFlex{
    display: flex;
    justify-content: flex-end;
}

.btnCont {
    display: flex;
    justify-content: flex-end;
}
.createBtn{
    background-color: #29CB97 !important;
    color: #ffffff !important;
    padding: 12px 35px !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
}

.addBtn{
    color: #2896E9 !important;
    font-weight: 600 !important;
    font-size: 0.7rem !important;
    margin-left: 60px !important;
}

.detailFlex{
    display: flex;
}

.info{
    margin-left: 10px;
}
.upperFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
