.flex {
    display: flex;
    align-items: center;
}
.panelCont {
    margin-top: 40px;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.image {
    width: 80px;
    height: 80px;
    /* object-fit: cover; */
    border-radius: 50%;
}
.tablesFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf7ff;
    position: sticky;
    top: 0;
}
.dFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #EBEDF4;
}
.values{
    display: flex;
    flex: 1 1;
    align-items: center;
    font-size: .875rem;
}
.tableLabel{
    font-size: 0.9rem;
    flex: 1 1;
    font-weight: bold;
    padding: 15px 0;
    padding-left: 25px;
}
.infoLabel {
    font-size: 0.9rem;
    font-weight: 500;
}

.titleText {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.tableTitletext{
    font-size: 0.9rem;
    font-weight: 500;
    /* margin-bottom: 10px; */
    text-transform: capitalize;
    padding-left: 25px;
    flex: 1 1;
    align-items: center;
}

.infoContainer {
    padding: 15px !important;
}
.tableContainer{
    padding: 0;
}
.tableData{
    overflow: auto;
    max-height: 100vh;
}
.imgClass{
    width: 70px;
    aspect-ratio: 1/1;
    margin-top: 15px;
}
.rejectBtn {
    margin-right: 20px !important;
    border-color: red !important;
    color: red !important;
    /* background: red !important; */
}

.tableFlex {
    justify-content: space-between;
}
.tableText {
    flex: 1;
    font-size: 0.8rem;
    margin: 5px 0px
}
.btnView{
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    /* flex-grow: 1; */
    margin-bottom: 15%;
}

.linkContainer{
    display: flex;
    align-items: flex-start;
    height: 80px !important;
}

.linkText{
    margin-top: 10px;
    color: blue;
    text-decoration: underline !important;
    height: 14px;
}
.key{
    color: #818181;
    font-weight: 500;
    margin-bottom: 7px;
    display: flex;
    gap: 10px;
    font-size: 14px;
}
.value{
    font-weight: 600;
    /* width: 100%; */
    color: black;
    display: flex;
    font-size: 14px;
}
.btm{
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
    align-items: center;
    font-size: 0.8rem;
    margin-left: 15px;
    margin-bottom: 15px !important;
}
@media only screen and (max-width:769px){
    .btnView{
        display: flex;
        width: 40% !important;
        gap: 10px;
        flex-direction: column !important;
    }
    
}
